import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantInfo } from 'src/app/models/dashboard/dashboard.model';
import { CasosDeUsoDashboard } from 'src/app/models/dashboard/casos-de-uso-dashboard.model';
import { Avatares } from 'src/app/models/dashboard/shared-models/casosdeuso/avatares.model';
import { GenericResponse } from 'src/app/models/common/generic-response.model';

const initialTenantInfo: TenantInfo = {
  data: 'Consultando datos...',
  dataB2C: 'Consultando datos...',
  haveTenant: false,
  tenantName: '',
  loading: true
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private interfaceTenantInfo = new BehaviorSubject<TenantInfo>(initialTenantInfo);

  private urlConsultaCasosDeUso = environment.urlConsultaCasosDeUso;
  private urlConsultarAvatares = environment.urlConsultaAvatares;
  private sidebarLinksState = new Subject<boolean>();
  private queryPermissions = new Subject<boolean>();

  private token = localStorage.getItem('token');

  constructor(private http: HttpClient) {

  }

  public get getTenantInfo$(): Observable<TenantInfo> {
    return this.interfaceTenantInfo.asObservable();
  }
  public setTenantInfo(tenantInfo: any): void {
    this.interfaceTenantInfo.next(tenantInfo);
  }

  public consultaCasosDeUso(tenant: string, canal: string): Observable<CasosDeUsoDashboard> {
    return this.http.get<CasosDeUsoDashboard>(`${this.urlConsultaCasosDeUso}${tenant}?canal=${canal}`);
  }

  public consultarAvatares(tenant: string): Observable<GenericResponse<Avatares[]>> {
    let headers = { 'Authorization': 'Bearer ' + this.token };
    return this.http.get<GenericResponse<Avatares[]>>(`${this.urlConsultarAvatares}${tenant}`, { headers })
  }

  public setSidebarLinksState(state: boolean): void {
    this.sidebarLinksState.next(state);
  }

  public getSidebarLinksState(): Observable<boolean> {
    return this.sidebarLinksState.asObservable();
  }

  public setQueryPermissions(permissions: boolean): void {
    this.queryPermissions.next(permissions);
  }

  public getQueryPermissions(): Observable<any> {
    return this.queryPermissions.asObservable();
  }
}
