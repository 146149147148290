// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const natiTransversalesBaseUrl = 'https://nati-transversales-dllo.azurewebsites.net/api';
const newNatiPanelCoreBaseUrl = 'https://nati-panel-core-api-dllo.azurewebsites.net/api';
// const natiFacturacionBaseUrl = 'https://nati-facturacion-dllo.azurewebsites.net/api';
const natiNotificacionesBaseUrl = 'https://nati-notification-dllo.azurewebsites.net/api';
const natiIntegrationsBaseUrl = 'https://nati-integration-dllo.azurewebsites.net/api';
const natiFacturacionBaseApm = 'https://nati-apim.azure-api.net/dllo/billing';
const talkingHeadsBaseUrl = 'https://woodcock-lenient-badger.ngrok-free.app';

export const environment = {
  // B2C Auth
  production: false,
  UrlBaseB2C: 'https://natisaas.onmicrosoft.com/c2d47157-26fb-41da-9d54-39355f077001/',
  ScopeApiRead: 'Api.Read',
  redirectUri: 'https://dllo.nati.ai',

  // Acortador de URL
  urlEnlaces: `${newNatiPanelCoreBaseUrl}/Enlaces`,
  urlConsultaSedesEPM: `${natiIntegrationsBaseUrl}/epm/headquarters`,

  //Apis Transversales
  urlConsultaAvatares: `${natiTransversalesBaseUrl}/avatar/`,

  // REVISAR DONDE SE USA
  urlCrearActualizarPersonaDigital: `https://apim-natisaas-prod-east.azure-api.net/servicios-transversales/api/personadigital`,

  //Caso de uso APIM
  urlConsultaCasosDeUsoAPIM: `${newNatiPanelCoreBaseUrl}/casos-de-uso/tenant/`,
  // Casos de uso
  urlConsultaCasosDeUso: `${natiTransversalesBaseUrl}/casos-uso/by-tenant/`,
  urlConsultaCasoDeUso: `${natiTransversalesBaseUrl}/casos-uso/by-id/`,
  urlCrearCasoDeUso: `${natiTransversalesBaseUrl}/casos-uso`,
  urlEditarCasoDeUso: `${natiTransversalesBaseUrl}/casos-uso/by-id/`,
  urlEliminarCasoDeUso: `${natiTransversalesBaseUrl}/casos-uso/by-id/`,

  urlCasosDeUso: `${newNatiPanelCoreBaseUrl}/casos-de-uso/`,

  // Flujos
  urlConsultaFlujos: `${natiTransversalesBaseUrl}/flujos/by-use-case-id/`,
  urlConsultaFlujo: `${natiTransversalesBaseUrl}/flujos/by-id/`,
  urlCrearFlujo: `${natiTransversalesBaseUrl}/flujos`,
  urlEditarFlujo: `${natiTransversalesBaseUrl}/flujos/by-id/`,
  urlEliminarFlujo: `${natiTransversalesBaseUrl}/flujos/by-id/`,
  urlConsultaPlantillas: `${natiTransversalesBaseUrl}/plantillas/tenant/`,

  urlFlujos: `${newNatiPanelCoreBaseUrl}/flujos/`,

  // Whatsapp
  urlNumeroDisponible: `${newNatiPanelCoreBaseUrl}/numero-whatsapp/disponible`,
  urlAsignarNumero: `${natiTransversalesBaseUrl}/numero-whatsapp/asignar/`,
  urlObtenerNumero: `${newNatiPanelCoreBaseUrl}/numero-whatsapp/`,
  urlObtenerLinkFlujo: `${newNatiPanelCoreBaseUrl}/numero-whatsapp/enlace-flujo/`,

  // Métricas NEW
  urlGraficaLinea: `${newNatiPanelCoreBaseUrl}/estadisticas/line-chart-by-caseUseId/`,
  urlGraficaBarra: `${newNatiPanelCoreBaseUrl}/estadisticas/bar-chart-by-caseUseId/`,
  urlGraficaDona: `${newNatiPanelCoreBaseUrl}/estadisticas/dona-chart-by-caseUseId/`,
  urlDescargarConversaciones: `${natiTransversalesBaseUrl}/estadisticas/conversations/as-excel/`,
  urlDescargarConsultasProyecto: `${newNatiPanelCoreBaseUrl}/estadisticas/as-excel/use-case-id/`,
  urlDescargarConsultasFlujo: `${newNatiPanelCoreBaseUrl}/estadisticas/as-excel/flow-id/`,
  urlConsultarAtendidos: `${natiTransversalesBaseUrl}/estadisticas/durations/use-case-id/`,
  urlConsultarAtendidosConUbicacion: `${newNatiPanelCoreBaseUrl}/estadisticas/duration/per-location/use-case-id/`,

  // Azure speech
  azureCognitiveServices: {
    apiKey: 'd7c31505e30144c3a66f151f4ab787ba',
    region: 'westus2'
  },

  // Notificaciones
  urlConsultaNotificaciones: `${natiNotificacionesBaseUrl}/notificaciones/panel/`,

  // Usuarios
  urlConsultaTenantUsuario: `${natiTransversalesBaseUrl}/tenant/`,
  urlCrearUsuario: `${natiTransversalesBaseUrl}/tenant`,
  urlEditarUsuario: `${natiTransversalesBaseUrl}/tenant/`,
  urlEliminarUsuario: `${natiTransversalesBaseUrl}/tenant/`,

  //Permisos
  UrlPermisos:`${newNatiPanelCoreBaseUrl}/permisos`,

  // Reportes
  urlReportes: `${natiTransversalesBaseUrl}/datos-flujos/inscripcion-candidatos/flujo/`,

  urlReportesSeleccionados: `${natiTransversalesBaseUrl}/entrevistas/tenant/konecta/rango-fechas`,
  urlReportesNoSeleccionados: `${natiTransversalesBaseUrl}/entrevistas/tenant/konecta/rango-fechas`,
  urlExcelSeleccionados: `${natiTransversalesBaseUrl}/entrevistas/tenant/konecta/excel`,
  urlExcelNoSeleccionados: `${natiTransversalesBaseUrl}/entrevistas/tenant/konecta/excel`,

  // Facturación
  urlObtenerPlanes: `${natiFacturacionBaseApm}/planes`,
  urlObtenerPlan: `${natiFacturacionBaseApm}/planes/`,
  urlCrearSuscripcion: `${natiFacturacionBaseApm}/suscripciones`,
  urlObtenerSuscripcionesTenant: `${natiFacturacionBaseApm}/suscripciones/`,
  urlObtenerSuscripcionesActivas: `${natiFacturacionBaseApm}/suscripciones/`,
  urlCrearFuentePago: `${natiFacturacionBaseApm}/metodos-pago`,
  urlObtenerFuentePago: `${natiFacturacionBaseApm}/metodos-pago/`,
  urlObtenerFacturas: `${natiFacturacionBaseApm}/facturas/`,
  urlEliminarMetodoPago: `${natiFacturacionBaseApm}/metodos-pago`,
  urlCambiarPredeterminado: `${natiFacturacionBaseApm}/metodos-pago/`,
  urlCambiarSuscripcion: `${natiFacturacionBaseApm}/suscripciones/cambiar`,
  urlCancelarSuscripcion: `${natiFacturacionBaseApm}/suscripciones/cancelar`,
  urlReactivarSuscripcion: `${natiFacturacionBaseApm}/suscripciones/reactivar/`,
  urlCliente: `${natiFacturacionBaseApm}/clientes`,
  urlCiudades: `${natiFacturacionBaseApm}/ciudades`,

  // Ubicaciones
  urlUbicaciones: `${natiTransversalesBaseUrl}/ubicaciones`,

  //Estadisticas (EPM)
  urlConsultaEstadisticas: `${natiTransversalesBaseUrl}/estadisticas`,


  // Vapid
  publicKey: 'pub_test_5cxYnSxtcyur7PizDsp0dzbvDZyjUIdY',
  wompiBase: 'https://sandbox.wompi.co/v1/',

  // App Nati
  urlBaseAppNati: 'https://dllo-app.nati.ai/',

  subscriptionKey: '381f7469c9cf429792eeb7584634771d',

  //Talking heads
  urlGenerateAvatarImage: `${talkingHeadsBaseUrl}/avatar`,
  urlAvatarParallandix: `${talkingHeadsBaseUrl}/talkinghead`,
  urlGetParallandixVideo: `${natiTransversalesBaseUrl}/Avatar/bundle`,

  //Subscription Key
  urlGetSubscriptionKeyWidget: `${newNatiPanelCoreBaseUrl}/api-management/nlpExterno`,
  urlGetSubscriptionKeySpeaker: `${newNatiPanelCoreBaseUrl}/api-management/narradora`,
  urlRegenerateSubscriptionKeyWidget: `${newNatiPanelCoreBaseUrl}/api-management/regenerar/nlpExterno`,
  urlRegenerateSubscriptionKeySpeaker: `${newNatiPanelCoreBaseUrl}/api-management/regenerar/narradora`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
